<template>
    <div id="page-camera-list">
        <vs-popup :title="$t('warningMenuCamera.titleWarning')" :active.camel="openPopupDeleteGroupCamera" class="stream_Camera_popup">
<!--            <div class="vx-row">-->
<!--                <div class="vx-row md:w-full w-full mt-2" >-->
<!--                    <div>Bạn có chắc là muốn xóa nhóm "{{nameGroup}}" không ?-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="notification-show-start mt-8 mb-8 mr-5 ml-5">{{$t('textCamera.deleteGroup')}}"{{nameGroup}}"</div>
<!--            <div class="vx-row">-->
<!--                <div class="vx-col w-full">-->
<!--                    <div class="items-center justify-end popup-relink">-->
<!--                        <vs-button class="ml-4 mt-2 text-size" icon-pack="feather"  @click="saveDelete">-->
<!--                            Xóa-->
<!--                        </vs-button>-->
<!--                        <vs-button class="ml-4 mt-2 text-size" type="border" @click="onClickCloseButton">Đóng-->
<!--                        </vs-button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--             Save & Reset Button-->
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex flex-wrap items-center justify-end">
                        <vs-button class="ml-auto mt-2" @click="saveDelete">
                        {{$t('button.delete')}}
                        </vs-button>
                        <vs-button class="ml-4 mt-2 text-size" type="border" @click="onClickCloseButton">{{$t('button.close')}}
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>

</template>


<script>
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'

export default {
    components: {
        draggable,
        Prism,
        vSelect
    },
    props:{
        openPopupDeleteGroupCamera:{
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            objectInfoGroup: {},
            nameGroup: null,
        }
    },
    watch: {
    },
    methods: {
        getObject: function (obj){
            this.objectInfoGroup = obj
            this.nameGroup = obj.cameraGroupName
        },
        onClickCloseButton() {
            this.openPopupDeleteGroupCamera = false;
            this.$emit('closePopupDeleteGroupCamera')
        },
        saveDelete(){
            this.$vs.loading();
            this.$crm.post(`/camera-group/delete/${this.objectInfoGroup.id}`).then(() => {
                this.showDeleteSuccess();
                this.$vs.loading.close();
                this.onClickCloseButton();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: this.$t('warningMenuCamera.deleteGroupSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    },
    mounted(){
        this.$eventBus.$on('delete-group-camera', ($event) => {
            if ($event) this.getObject($event.value);
        })
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButton, false);
    }
}
</script>
<style lang="scss" scoped>
.notification-show-start {
    font-size: 20px;
    color: #058962;
}
.popup-relink {
    margin-left: 120px;
}
</style>
