<template>
<div id="page-branch-list">
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="vx-card p-4">
                <div class="flex w-full">
                    <feather-icon icon="VideoIcon" style="width:1.2rem; vertical-align: middle;" />
                    <span class="ml-4 font-bold" style="font-size:16px"> {{ cameraName }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="vx-row mt-4">
        <div class="vx-card p-4 ml-4 mr-4">
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-card">
                        <div class="component bg-primary eye-custom text-center text-white cursor-pointer"  @click="viewHeatmap">
                            <feather-icon icon="EyeOffIcon" class="mt-1" style="width: 1.2rem;vertical-align: sub;" v-if="!isHeatmap"></feather-icon>
                            <feather-icon icon="EyeIcon" class="mt-1" style="width: 1.2rem;vertical-align: sub;" v-else></feather-icon>
                        </div>
                        <div class="w-full component text-center text-white custom-message" v-if="!isHeatmap">
                            <div class="w-full">
                                <feather-icon icon="EyeOffIcon"></feather-icon>
                            </div>
                            <span class="font-bold">Chưa có dữ liệu</span>
                        </div>
                        <div class="w-full component">
                            <div class="component color-custom" v-if="isHeatmap">
                                <img src="@/assets/images/pages/color.jpg" alt="" height="200" width="7" class="image-color">
                            </div>
                            <div class="component w-full image-card cursor-pointer" v-if="isHeatmap">
                                <img :src="'data:image/jpeg;base64,' + imageHeatmap" alt="" class="image-border w-full h-full"> 
                            </div>
                            <div class="component w-full image-card" v-else>
                                <img @error="imgError" :src="'data:image/jpeg;base64,' + imageFrame" alt="" class="image-border w-full h-full "> 
                            </div>
                        </div>
                        <div class="component bg-primary text-white float-right download-image p-1 cursor-pointer" @click="downloadImage" v-if="imageFrame || imageHeatmap">
                            <feather-icon icon="DownloadIcon" style="width:1.2rem;vertical-align: bottom;"></feather-icon>
                            <span class="ml-1">Tải xuống</span>
                        </div>
                    </div>
                </div>
                <div class="vx-col md:w-1/2 w-full">
                        <div class="vx-col w-full">
                            <div class="component w-full font-bold" style="font-size:18px">
                                <span>Heatmaps </span>
                            </div>
                        </div>

                        <div class="vx-row mt-2">
                            <div class="vx-col w-full mt-4">
                                <span class="font-bold">Camera </span>
                                <span class="text-danger">*</span>
                            </div>
                            <div class="vx-col w-full mt-2" v-if="heatmap.organizationBranchId">
                                <v-select :disabled="isUpdate"
                                            v-model="selectedCamera"
                                            :clearable="false"
                                            :options="cameraOptions">
                                    <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                </v-select>
                                <span class="text-danger text-sm">{{ errors.first('cameraName') }}</span>
                            </div>
                            
                            <div class="vx-col w-full mt-4">
                                <span class="font-bold">{{ $t('heatmap.analysisTime') }}</span>
                            </div>
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <datepicker :highlighted="highlighted" :language="languages['vi']" :format="'dd/MM/yyyy'" v-model="timeSelect.datepicker"
                                            data-vv-validate-on="input" v-validate="'required'" name="expiredDate"></datepicker>
                                <span class="text-danger text-sm">{{ errors.first('expiredDate') }}</span>
                            </div>

                            <div class="vx-col md:w-1/3 w-full mt-2" style="margin-left:-10px">
                                <v-select :value="timeSelect.fromHourString" :clearable="false" :options="timeSpans"
                                            @input="fromHour => updateTimeSpan(timeSelect, fromHour, 'fromHour')"
                                            v-validate="'required'">
                                    <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                </v-select>
                            </div>
                            <span class="mt-4">:</span>
                            
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <v-select :value="timeSelect.toHourString" :clearable="false" :options="timeSpans"
                                            @input="toHour => updateTimeSpan(timeSelect, toHour, 'toHour')"
                                            v-validate="'required'">
                                    <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                </v-select>
                            </div>
                        </div>

                        <div class="vx-row mt-4">
                            <div class="vx-col component w-full">
                                <span class="font-bold">{{ $t('heatmap.analysisZone') }}</span>
                            </div>
                            <div class="component m-auto mt-2">
                                <img src="@/assets/images/pages/color-transform.jpg" alt="" class="image-color" style="border-radius:5px;max-width:36vw">
                            </div>
                            <div class="vx-col w-full mt-2">
                                <span style="color:#b9b9c3">
                                    {{ $t('heatmap.text_1') }}
                                </span>
                            </div>
                            <div class="vx-col w-full mt-2">
                                <span class="mt-2" style="color:#b9b9c3">
                                    {{ $t('heatmap.text_2') }}
                                </span>
                            </div>
                        </div>

                        <div class="vx-row mt-4">
                            <div class="vx-col component w-full">
                                <span class="font-bold">{{ $t('heatmap.temperZone') }}</span>
                            </div>
                            <div class="vx-col w-full mt-2" v-for="(heatmap, indexHeatMap) in heatmapResolution" :key="indexHeatMap">
                                <div class="w-full flex" >
                                    <div class="component top-heatmap" :style="'background:' + heatmap.color">
                                    </div>
                                    <span class="ml-4">{{ heatmap.title }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="vx-row mt-4">
                            <div class="vx-col w-full ml-auto">
                                <div class="flex flex-wrap">
                                    <vs-button class="ml-auto" @click="$router.go(-1)" type="border" icon="icon-chevron-left" icon-pack="feather">{{ $t('button.back') }}</vs-button>
                                    <vs-button class="ml-4" @click="previewImage" icon="icon-map" icon-pack="feather">{{ $t('heatmap.analysisData') }}</vs-button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vSelect from 'vue-select';
import * as lang from 'vuejs-datepicker/src/locale';
import Datepicker from 'vuejs-datepicker';
import HeatMap from './HeatmapService.vue';
import utils from "../../../components/utils.js";
import moment from 'moment';
import CameraTree from '../../../components/camera-tree/CameraTree.vue';
import NoImage from '@/assets/images/pages/no_image.png'
export default {
    components:{
        vSelect,
        Datepicker,
        HeatMap,
        CameraTree
    },
    data(){
        return {
            cameraName: null,
            requestHeatmap: {
                frame: null
            },
            //sub  text
            heatmapResolution:[
                {title: this.$t('heatmap.veryHigh'), color: '#feffbd'},
                {title: this.$t('heatmap.high'), color: '#feb000'},
                {title: this.$t('heatmap.medium'), color: '#e50201'},
                {title: this.$t('heatmap.low'), color: '#2b0001'},
            ],
            isUpdate: false,
            imageFrame: null,
            imageHeatmap: null,
            //camera
            cameraOptions: [],

            //popup heatmap
            activeHeatmapConfig: false,
            heatmapId: null,
            heatmap: {
                cameraId: null
            },
            levelRegion: [
                {title: '- Cao'},
                {title: '- Trung bình'},
                {title: '- Thấp'}
            ],
            isHover: false,
            showHeatmap: false,
            languages: lang,
            highlighted : {
                dates: [
                    new Date()
                ]
            },
            isHeatmap: false,
            isSettingHeatmap: false,
            timeResolution: [
                {label: '1 giờ', value: 1},
                {label: '2 giờ', value: 2},
                {label: '3 giờ', value: 3},
                {label: '4 giờ', value: 4},
            ],
            timeSpans: [],
            timeSelect: {
                resolution: 1,
                fromHourString: '00:00',
                toHourString: '23:59',
                datepicker: new Date(),
                cameraId: null,
                organizationBranchId: null
            },
        }
    },
    computed: {
        selectedCamera: {
            get() {
                let object = this.cameraOptions.find(el => el.value == this.timeSelect.cameraId);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.timeSelect.cameraId = object ? object.value : null;
                this.cameraName = object.label;
            }
        },
        resolutionModel: {
            get(){
                let object = this.timeResolution.find(el => el.value == this.timeSelect.resolution);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(obj){
                this.timeSelect.resolution = obj ? obj.value : null;
            }
        }
    },
    methods: {
        imgError(image){
            image.target.src = NoImage;
            image.width = "100";
            image.height = "100"
        },
        downloadImage: function(){
            var a = document.createElement("a");
            let image = this.isHeatmap ? this.imageHeatmap : this.imageFrame;
            if (!image) {
                return this.$vs.notify({
                    color: 'danger',
                    text: 'Không có dữ liệu ảnh.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            }
            a.href = "data:image/png;base64," + image;
            let name = this.isHeatmap ? 'Heatmap' + '.png' : 'Frame' + '.png';
            a.download = name;
            a.click();
        },
        previewImage: function(){
            return new Promise((resolve, reject) => {
                let object = this.cameraOptions.find(el => el.value == this.timeSelect.cameraId);
                if (!object) return;
                const payload = {
                    url: object.streamUrl
                }
                this.$vs.loading();
                this.$crm.post('/camera/preview', payload).then((res) => {
                    if (res.data.image) {
                        this.requestHeatmap.frame = res.data.image;
                        this.generateImage();
                    }
                    this.$vs.loading.close();
                    resolve()
                }).catch((err) => {
                    reject();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
        },
        getCamera() {
            return new Promise((resolve, reject) => {
                let url = `/heatmap/camera-heatmap`;
                this.$crm.get(url).then((response) => {
                    if (response.data.length > 0) {
                        this.cameraOptions = response.data.map(item => {
                            return {value: item.id, label: item.cameraName, streamUrl: item.streamUrl}
                        });
                    }
                    resolve();
                }).catch((err) => {
                    reject();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        updateTimeSpan(setting, hour, type) {
            setting[type] = hour.value;
            setting[`${type}String`] = hour.label;

        },
        generateImage: function(){
            this.imageHeatmap = this.requestHeatmap.frame;
            let timeStamp = moment(this.timeSelect.datepicker).format('DD/MM/YYYY');
            let startDate = moment(`${timeStamp} ${this.timeSelect.fromHourString}`, 'DD/MM/YYYY HH:mm:ss').format();
            let endDate = moment(`${timeStamp} ${this.timeSelect.toHourString}`, 'DD/MM/YYYY HH:mm:ss').format();
            if (!this.requestHeatmap) return;
            if (this.requestHeatmap) {
                this.requestHeatmap.startDuration = startDate;
                this.requestHeatmap.endDuration = endDate;
                this.requestHeatmap.cameraId = this.timeSelect.cameraId;
            }
            this.$vs.loading();
            this.$crm.post('/heatmap/image-heatmap', this.requestHeatmap).then((res) => {
                if (res.data.image) this.imageHeatmap = res.data.image;
                else this.imageHeatmap = this.requestHeatmap.frame;
                this.isHeatmap  = true;
                this.$vs.loading.close();
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        viewHeatmap: function(){
            this.isHeatmap = !this.isHeatmap;
        },
        findById: function(){
            return new Promise((resolve, reject) => {
                const payload = {
                    id: this.heatmapId
                }
                this.$crm.post('/heatmap/find-by-id', payload).then((res) => {
                    this.heatmap = res.data;
                    resolve();
                }).catch((err) => {reject()})
            })
        },
        async initScreen(){
            let query = this.$route.query;
            if (query.cameraId) this.timeSelect.cameraId = parseInt(query.cameraId);
            if (query.branchId) this.timeSelect.organizationBranchId = parseInt(query.branchId);
            if (query.id) this.heatmapId = query.id;
            if (query.request) {
                let object = JSON.parse(query.request);
                if (object.startDate) this.timeSelect.datepicker = object.startDate;
                this.requestHeatmap = object;
                this.requestHeatmap.startDuration = new Date(object.startTime);
                this.requestHeatmap.endDuration = new Date(object.endTime);
            }
            await this.findById();
            await this.getCamera().then(() => {
                let camera = this.cameraOptions.find(el => el.value == this.timeSelect.cameraId);
                if (camera) this.cameraName = camera.label;
            });
            this.previewImage();
        }
    },
    created(){
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        this.initScreen();
    }
}
</script>

<style lang="scss">
.image-card {
    height: 600px;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
}
.image-border {
    border-radius: 5px;
}
.time-border {
    border-radius: 0px 0px 5px 5px;
}
.custom-message {
    position: absolute;
    top: 50%;
}
.eye-custom {
    width: 30px;
    position: absolute;
    right: 0px;
    border-radius: 0px 5px 0px 5px;
}
.bg-first-login {
  height: 100vh;
  z-index: 200000;
  position: fixed;
  top: 0px;
  padding-top: 100px;
  background: rgb(0, 0, 0, 0.2);
  right: 0;
  left: 0;
}
.view-image-custom {
    max-height:510px; 
    border-radius: 0px 0px 5px 5px;
    margin-bottom: -10px
}
.card-view {
    min-height:40rem;
     box-shadow: 0px 4px 25px 0px rgb(102 107 108);
}
.color-custom {
    position: absolute;
    left: 10px;
    top: 25px;
    border-radius: 5px;
}
.color-custom-popup {
    position: absolute;
    left: 5px;
    top: 60px;
    border-radius: 5px;
}
.image-color {
    border-radius: 5px;
}
.hover-color { 
    position: absolute;
    left: 20px;
    top: 60px;
    border-radius: 5px;
    height: 200px;
    // width: 50px;
    font-size: 12px;
    color: white;
}
.top-heatmap {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.download-image {
    height: 30px;
    width: 100px;
    position: absolute;
    text-align: center;
    right: 35px;
    top: 0;
    border-radius: 0px 0px 5px 5px;
}
</style>