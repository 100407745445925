<template>
    <div class="vx-col md:w-full sm:w-auto text-center">
        <ul style="display: flex; align-items: center; justify-content: center;">
            <li class="mr-2">
                <vx-tooltip :text="$t('button.edit')" position="top">
                    <feather-icon icon="EditIcon" class="action-success"
                                  svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                                  @click="editRecord"/>
                </vx-tooltip>
            </li>
            <li>
                <vx-tooltip :text="$t('button.delete')" position="top">
                    <feather-icon icon="TrashIcon" class="action-err ml-2"
                                  svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                  @click="confirmDeleteRecord"/>
                </vx-tooltip>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'HistoryCellRendererActions',
        components: {
        },
        data() {
            return {
                showPopupShareGroup: false,
            }
        },
        created() {
        },
        computed: {
        },
        methods: {
            editRecord() {
                this.$eventBus.$emit('edit-group-share', this.params.data);
            },
            confirmDeleteRecord() {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: `${this.$t('button.titleDelete')}`,
                    text: `${this.$t('shareGroupCameraHistory.delete')} "${this.params.data.targetEmail}"?`,
                    accept: this.deleteRecord,
                    acceptText: `${this.$t('button.delete')}`,
                    cancelText: `${this.$t('button.cancel')}`,
                })
            },
            deleteRecord() {
                this.$vs.loading();
                this.$crm.post(`/camera-group-share/delete?targetEmail=${this.params.data.targetEmail}&cameraGroupId=${this.params.data.cameraGroupResponseDTO.id}`).then(() => {
                    this.$vs.loading.close();
                    this.$eventBus.$emit("reload-data-history")
                    return this.showDeleteSuccess();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            },
            showDeleteSuccess() {
                this.$store.commit('DELETE_CAMERA_GROUP_SHARE', true);
                this.$vs.notify({
                    color: 'success',
                    text: `${this.$t('shareGroupCameraHistory.successful')}`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                })
            },
        },
    }
</script>
