<template>
<div id="page-branch-list">
    <div class="vx-row">
        <div class="vx-card p-8 ml-4 mr-4 text-center m-auto">
            <div class="w-full">
                <span class="font-bold" style="font-size:18px">Heatmap</span>
            </div>
            <div class="w-full mt-6">
                <span class="font-bold" style="font-size:16px">
                    {{ $t('heatmap.title') }}
                </span>
            </div>
            <div class="vx-row mt-2 p-6">
                <div class="vx-col md:w-1/3 w-full mb-8 mt-8" v-for="(card, indexCard) in  listCard" :key="indexCard">
                    <div class="component w-full">
                        <img :src="card.icon" alt="" width="50" height="100">
                    </div>
                    <div class="component w-full container">
                        <span>{{ card.title }}</span>
                    </div>
                </div>
            </div>

            <div class="vx-row">
                <div class="w-full m-">
                    <vs-button @click="$router.push('/user/add-heat-map-details')">{{ $t('heatmap.settingCamera') }}</vs-button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            listCard: [
                {icon:  require('@/assets/images/pages/analysis-s.svg'), title: this.$t('heatmap.title')},
                {icon:  require('@/assets/images/pages/promotions.svg'), title: this.$t('heatmap.feature_1')},
                {icon:  require('@/assets/images/pages/increase-s.svg'), title: this.$t('heatmap.feature_2')},
                {icon:  require('@/assets/images/pages/sort-s.svg'), title: this.$t('heatmap.feature_3')},
                {icon:  require('@/assets/images/pages/plan-s.svg'), title: this.$t('heatmap.feature_4')},
                {icon:  require('@/assets/images/pages/rating.svg'), title: this.$t('heatmap.feature_5')},
            ]
        }
    }
}
</script>

<style>

</style>
