<template>
    <div id="">
        <vs-popup class="shareGroupHistory" :title="this.$t('shareGroupCameraHistory.title')" :active.camel="openPopup">
            <div class="history-page">
                <div class="flex flex-wrap items-center">
                    <div class="flex-grow">
                        <H2><b>{{cameraGroupName}}</b></H2>
                    </div>
                    <vs-button class="mr-4" type="border" @click="closeShareHistory">{{this.$t('shareGroupCameraHistory.close')}}
                    </vs-button>
                    <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                              v-model="searchQuery"
                              autocomplete="nope"
                              @input="updateSearchQuery" :placeholder="this.$t('shareGroupCameraHistory.search')"/>
                </div>
                <!-- AgGrid Table -->
                <ag-grid-vue ref="agGridTable"
                             :components="components"
                             :gridOptions="gridOptions"
                             class="ag-theme-material w-100 my-4 ag-grid-table"
                             :columnDefs="columnDefs"
                             :defaultColDef="defaultColDef"
                             :rowData="cameraGroupShareList"
                             :animateRows="true"
                             :pagination="true"
                             :localeText=" {noRowsToShow: nodata}"
                             :paginationPageSize="paginationPageSize"
                             :onGridReady="onGridReady"
                             :suppressPaginationPanel="true">
                </ag-grid-vue>
                <div class="vx-row mt-10 items-center" v-if="openPopup">
                    <div class="vx-col md:w-1/2">
                        <Pagination
                            :itemList="cameraGroupShareList"
                            :currentPage="currentPage"
                            :paginationPageSize="paginationPageSize"
                            :gridApi="gridApi"
                        />
                    </div>
                    <div class="vx-col md:w-1/2">
                        <vs-pagination
                            :total="totalPages"
                            :max="maxPageNumbers"
                            v-model="currentPage"/>
                    </div>
                </div>
            </div>
            <share-group-camera :open-popup="showPopupShareGroup" @closeShare="closeShareGroup" :group-camera="groupCamera"></share-group-camera>
        </vs-popup>
    </div>
</template>

<script>
import Vue from "vue";
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../../ui-elements/Pagination.vue"
import HistoryCellRendererActions from "../cell-renderer/HistoryCellRendererActions.vue";
import ShareGroupCamera from "./ShareGroupCamera";
export default {
    components: {
        AgGridVue,
        Pagination,
        HistoryCellRendererActions,
        ShareGroupCamera
    },
    data() {
        return {
            nodata: this.$t('shareGroupCameraHistory.noData'),
            showPopupShareGroup: null,
            cameraGroupName: null,
            cameraGroupShareList: [],
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Id',
                    field: 'id',
                    width: 50
                },
                {
                    headerName: this.$t('shareGroupCameraHistory.organization'),
                    field: 'targetEmail',
                    width: 100,
                },
                {
                    headerName: this.$t('shareGroupCameraHistory.permission'),
                    field: 'cameraGroupShareTypesString',
                    width: 100,
                },
                {
                    headerName: this.$t('shareGroupCameraHistory.action'),
                    field: 'actions',
                    width: 50,
                    cellRendererFramework: Vue.extend(HistoryCellRendererActions)
                },
            ],
            // Cell Renderer Components
            components: {
                HistoryCellRendererActions,
            },
            groupCameraShareTypes: [
                {value: 0, label: this.$t('shareGroupCamera.live')},
                {value: 1, label: this.$t('shareGroupCamera.playback')},
                {value: 2, label: this.$t('shareGroupCamera.event')}
            ],
            groupCamera: null,
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        groupCameraValue: {
            type: Object
        }
    },
    watch:{
        openPopup(){
            if (this.openPopup) {
                this.cameraGroupName = this.groupCameraValue.cameraGroupName;
                this.findById(this.groupCameraValue.id);
            }
        },
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateCameraGroupShare'(val) {
            if (val) {
                if (this.groupCamera) this.findById(this.groupCamera.cameraGroupResponseDTO.id);
                this.$store.commit('UPDATE_CAMERA_GROUP_SHARE', false);
            }
        },
        '$store.state.isDeleteCameraGroupShare'(val) {
            if (val) {
                if (this.groupCamera) this.findById(this.groupCamera.cameraGroupResponseDTO.id);
                this.$store.commit('DELETE_CAMERA_GROUP_SHARE', false);
            }
        },
    },
    created() {
        if (this.groupCameraValue) this.groupCamera = this.groupCameraValue;
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        closeShareGroup(val){
            this.showPopupShareGroup = false;
            if(val == true){
                this.findById(this.groupCameraValue.id)
            }
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        getLabelGroupShareType(labelIds) {
            let labels = [];
            labelIds.forEach(labelId => {
                if (this.groupCameraShareTypes.find(x => x.value == labelId)) labels.push(this.groupCameraShareTypes[labelId].label);
            })
            return labels.join(", ");
        },
        findById(id){
            this.$crm.get(`/camera-group-share/find-by-group?id=${id}`).then((response) => {
                response.data.forEach(x => {
                    let sharePermissionTypes = JSON.parse(x.sharePermissionTypes);
                    x.cameraGroupShareTypesString = this.getLabelGroupShareType(sharePermissionTypes);
                });
                this.cameraGroupShareList = response.data;
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        closeShareHistory() {
            this.$emit("closeShareHistory")
        },
    },
    mounted(){
        this.$eventBus.$on("edit-group-share", (data) => {
            this.groupCamera = data;
            this.showPopupShareGroup = true;
        });
        this.gridApi = this.gridOptions.api;
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.closeShareHistory, false);
        this.$eventBus.$on("reload-data-history", () => {
            this.findById(this.groupCameraValue.id)
        })
    }
}
</script>

<style>
.history-page{
    /* min-width: 1000px; */
}

.shareGroupHistory .vs-popup {
    width: 1100px !important;
}
</style>
