<template>
    <div id="">
        <vs-popup class="shareGroupCamera" :title="this.$t('shareGroupCamera.title')" :active.camel="openPopup">
            <div class="vx-row">
                <div class="vx-col md:w-full">
                    <vs-input class="w-full" :label="this.$t('shareGroupCamera.email')" v-model="email" :disabled="isUpdate"
                              data-vv-validate-on="blur"
                              v-validate="'required|email'" name="email"/>
                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                </div>
            </div>
            <div class="vx-col md:w-full mt-2">
                <label class="vs-input--label">{{this.$t('shareGroupCamera.permission')}}</label>
            </div>
            <div class="vx-row row-permission">
                <div class="vx-col md:w-full mt-5">
                    <vs-checkbox v-model="liveModel">{{this.$t('shareGroupCamera.live')}}</vs-checkbox>
                </div>
                <div class="vx-col md:w-full mt-5">
                    <vs-checkbox v-model="playBackModel">{{this.$t('shareGroupCamera.playback')}}</vs-checkbox>
                </div>
                <div class="vx-col md:w-full mt-5">
                    <vs-checkbox v-model="eventModel">{{this.$t('shareGroupCamera.event')}}</vs-checkbox>
                </div>
            </div>


            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                        <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{this.$t('shareGroupCamera.share')}}
                        </vs-button>
                        <vs-button class="ml-4 mt-2" type="border" color="warning" @click="closePopup">{{this.$t('shareGroupCamera.close')}}
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-popup>

    </div>
</template>

<script>
import vSelect from 'vue-select';
export default {
    components: {
        vSelect
    },
    data() {
        return {
            email: null,
            groupCameraShareTypes: [
                {value: 0, label: this.$t('shareGroupCamera.live')},
                {value: 1, label: this.$t('shareGroupCamera.playback')},
                {value: 2, label: this.$t('shareGroupCamera.event')}
            ],
            groupCameraShareTypeUse: [],
            cameraGroupUpdate: null,
            isUpdate: false,
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false
        },
        groupCamera: {
            type: Object
        }
    },
    watch:{
        openPopup(){
            if (this.groupCamera && this.groupCamera.targetEmail) {
                this.isUpdate = true;
                this.groupCameraShareTypeUse = JSON.parse(this.groupCamera.sharePermissionTypes);
                this.email = this.groupCamera.targetEmail;
            } else {
                this.email = null;
                this.groupCameraShareTypeUse = [];
                this.$validator.reset();
            }
        },
    },
    created() {
    },
    computed: {
        liveModel: {
            get() {
                if (this.groupCameraShareTypeUse.includes(this.groupCameraShareTypes[0].value)) return true;
                return false;
            },
            set(value){
                if(value) {
                    this.groupCameraShareTypeUse.push(this.groupCameraShareTypes[0].value);
                } else {
                    this.groupCameraShareTypeUse = this.groupCameraShareTypeUse.filter(x => x != this.groupCameraShareTypes[0].value);
                }
            }
        },
        playBackModel: {
            get() {
                if (this.groupCameraShareTypeUse.includes(this.groupCameraShareTypes[1].value)) return true;
                return false;
            },
            set(value){
                if(value) {
                    this.groupCameraShareTypeUse.push(this.groupCameraShareTypes[1].value);
                } else {
                    this.groupCameraShareTypeUse = this.groupCameraShareTypeUse.filter(x => x != this.groupCameraShareTypes[1].value);
                }
            }
        },
        eventModel: {
            get() {
                if (this.groupCameraShareTypeUse.includes(this.groupCameraShareTypes[2].value)) return true;
                return false;
            },
            set(value){
                if(value) {
                    this.groupCameraShareTypeUse.push(this.groupCameraShareTypes[2].value);
                } else {
                    this.groupCameraShareTypeUse = this.groupCameraShareTypeUse.filter(x => x != this.groupCameraShareTypes[2].value);
                }
            }
        },

        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        closePopup(val) {
            this.$emit("closeShare", val)
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                this.$vs.loading();
                if (!this.isUpdate) {
                    let checkExistEmail = await this.checkExistEmail(this.email);
                    if (!checkExistEmail) {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: this.$t('shareGroupCamera.validEmail'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                }
                this.shareGroupCamera();
            } catch (e) {
                console.log(e);
            }
        },
        shareGroupCamera() {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
            let value = {
                sourceEmail: this.userInfo.email,
                targetEmail: this.email,
                sharePermissionTypes: JSON.stringify(this.groupCameraShareTypeUse),
                cameraGroupId: this.isUpdate ? this.groupCamera.cameraGroupResponseDTO.id : this.groupCamera.id
            };
            let url = this.isUpdate ? "/camera-group-share/update" : "/camera-group-share/create";
            this.$crm.post(url, value).then(() => {
                this.$vs.notify({
                    color: 'success',
                    text: this.$t('shareGroupCamera.shareComplete'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$store.commit('UPDATE_CAMERA_GROUP_SHARE', true);
                this.$vs.loading.close();
                this.closePopup(true);
            }).catch(err => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        checkExistEmail(email) {
            return new Promise((resolve, reject) => {
                this.$oauth.get(`/user/check-exist-email?email=${email}`).then((response) => {
                    resolve(response.data);
                }).catch(() => {
                    reject(null)
                })
            })
        },
    },
    mounted(){
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.closePopup, false);
    }
}
</script>

<style scoped>
.row-permission {
    margin-left: 5px;
}
</style>

<style lang="scss">
.shareGroupCamera .vs-popuup {
    width: 700px !important;
}
</style>
