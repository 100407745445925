<template>
    <div class="vx-row p-3" id="content-tree-v">

        <vs-input class="md:w-full mb-5" :placeholder="$t('button.search')" v-model="searchWordModel" />

        <VuePerfectScrollbar class="scroll-area--nofications-dropdown" :settings="settings">
            <v-tree ref='tree' :multiple="false" :selectAlone="true" id="tree-camera" :canDeleteRoot="false"
                :data='treeData' @node-click="nodeSelected" :draggable='false' :tpl='tpl' :halfcheck='true'
                :radio="true" />
        </VuePerfectScrollbar>

        <add-group-camera :openPopupAddGroupCamera="showPopupAddGroupCamera"
            @closePopupAddGroupCamera="closePopupAddGroupCamera"></add-group-camera>
        <add-group-branch :openPopupAddGroupBranch="showPopupAddGroupBranch"
            @closePopupAddGroupBranch="closePopupAddGroupBranch"></add-group-branch>
        <edit-group-camera :openPopupEditGroupCamera="showPopupEditGroupCamera"
            @closePopupEditGroupCamera="closePopupEditGroupCamera"></edit-group-camera>
        <delete-group-camera :openPopupDeleteGroupCamera="showPopupDeleteGroupCamera"
            @closePopupDeleteGroupCamera="closePopupDeleteGroupCamera"></delete-group-camera>
        <share-group-camera :open-popup="showPopupShareGroup" @closeShare="closeShareGroup" :group-camera="groupCamera">
        </share-group-camera>
        <share-group-camera-history :open-popup="showPopupShareGroupHistory" @closeShareHistory="closeShareGroupHistory"
            :group-camera-value="groupCamera"></share-group-camera-history>
        <add-camera :openPopupAddCamera="showPopupAddCamera" @closePopupAddCamera="closePopupAddCamera"></add-camera>
        <vs-popup title="Thông báo" :active.sync="branchEmpty">
            <span class="flex justify-center text-center">{{ $t('textCamera.branchEmpty') }}</span>
            <div class="flex justify-center">
                <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="btn-add mt-4"
                    @click="clickAddCBranch">
                    <span class="text_add-camera">{{ $t('button.addCamera') }}</span>
                </vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { VSelectTree, VTree } from 'vue-tree-halower'
import ShareGroupCamera from "./../../views/user-pages/camera/my-cameras/ShareGroupCamera.vue";
import ShareGroupCameraHistory from "./../../views/user-pages/camera/my-cameras/ShareGroupCameraHistory";
import AddGroupBranch from "./../../views/user-pages/camera/my-group-camera/AddGroupBranch";
import DeleteGroupCamera from "./../../views/user-pages/camera/my-group-camera/DeleteGroupCamera";
import AddGroupCamera from "./../../views/user-pages/camera/my-group-camera/AddGroupCamera";
import EditGroupCamera from "./../../views/user-pages/camera/my-group-camera/EditGroupCamera";
import AddCamera from "../../views/user-pages/camera/my-cameras/AddCamera";
import addShareIcon from "./../../assets/images/pages/add-share.png";
import editShareIcon from "./../../assets/images/pages/edit-share-icon.png";

import imgErr from "./../../assets/images/pages/cam-error.png";
import imgWaiting from "./../../assets/images/pages/cam-waiting.png";
import imgSuccess from "./../../assets/images/pages/cam-succes.png";
import imgEmpty from "./../../assets/images/pages/camera_empty_height_480.png";
import imgLocation from "./../../assets/images/pages/location.png";
import { defaults } from 'chart.js';

export default {
    props: {
        camType: {
            type: Number,
            default: 0,
        },
        isMap: {
            type: Boolean,
            default: false,
        },
        refreshData: {
            type: Number,
            default: 0
        },
        heightTree: {
            type: Number,
            default: 0,
        },
        disabelOrganization: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            objectInfoGroupid: null,
            sumObjValueCameraMap: [],
            objValueCameraMap: [],
            searchWord: '',
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .30
            },
            treeData: [{
                isOrganization: true,
                title: null,
                expanded: true,
                children: []
            }],
            showPopupAddCamera: false,
            showPopupAddGroupCamera: false,
            showPopupAddGroupBranch: false,
            showPopupEditGroupCamera: false,
            showPopupDeleteGroupCamera: false,
            showPopupRename: false,
            showPopupDetail: false,
            showPopupNotify: false,
            showPopupShareGroup: false,
            showPopupShareGroupHistory: false,
            camera: {
                id: null,
                organizationBranchId: null
            },
            asyncPopupNotification: false,
            activePopupNotification: false,
            groupCamera: null,
            addShareIcon: null,
            editShareIcon: null,
            maxHeight: 0,
            saveNode: null,
            historyPackage: [],
            fistListCamera: [],
            treeDataOld: [],
            count: false,
            branchEmpty: false,
        };
    },
    components: {
        VuePerfectScrollbar,
        VTree,
        VSelectTree,
        ShareGroupCamera,
        ShareGroupCameraHistory,
        AddGroupBranch,
        DeleteGroupCamera,
        AddGroupCamera,
        EditGroupCamera,
        AddCamera,
        imgErr,
        imgWaiting,
        imgSuccess,
        imgEmpty,
        imgLocation,
    },
    computed: {
        searchWordModel: {
            get() {
                return this.searchWord;
            },
            set(value) {
                this.searchWord = value;
            }
        },
    },
    methods: {
        tpl(...args) {
            // <img v-if="false" src={this.imgErr}/>
            let { 0: nodeValue, 2: parent, 3: index } = args
            let titleClass = ''
            titleClass = nodeValue.selected ? 'node-selected' : ''
            if (nodeValue.searched) titleClass += ' node-searched'
            let img = null;
            let renderIconMap = null;
            if (this.isMap && (nodeValue.isCamera) && (nodeValue.value.longitude !== null) && (nodeValue.value.latitude !== null)) {
                renderIconMap = <img src={this.imgLocation} style="width:16px; margin-left: 5px" />
            }
            if (nodeValue.isCamera) {
                if (!nodeValue.value.evgCamera) return;
                let streamingStatus = nodeValue.value.evgCamera.streamingStatus;
                switch (streamingStatus) {
                    case 0:
                        img = <img style="width:16px; margin-left: 5px" src={this.imgErr} />;
                        break;
                    case 1:
                        img = <img style="width:16px; margin-left: 5px" src={this.imgSuccess} />;
                        break;
                    case 8:
                        img = <img style="width:16px; margin-left: 5px" src={this.imgWaiting} />;
                        break;
                    default:
                        img = <img style="width:16px; margin-left: 5px" src={this.imgErr} />;
                        break;
                }
            }
            let addItemBranch = null;
            let addItemGroupBranch = null;
            if (nodeValue.isOrganization) {
                img = <vx-tooltip class="f-r" text={this.$t('menuCamera.organization')} position="top">
                    <feather-icon onClick={() => this.clickNodeItem(nodeValue)} icon="HomeIcon"
                        svgClasses="h-6 w-6 cursor-pointer" style="color: rgba(var(--vs-primary),1);" />
                </vx-tooltip>
                if (!this.camType && !this.isMap) {
                    addItemGroupBranch = <vs-dropdown vs-trigger-click class="cursor-pointer" svgClasses="w-6 h-6" style="left: 307px">
                        <feather-icon icon="MoreVerticalIcon" svgClasses="h-6 w-6 cursor-pointer" style={"color: " + titleClass ? "#fff" : "#9faab6"} />
                        <vs-dropdown-menu>
                            <vs-dropdown-item onClick={() => this.showPopupAddGroupBranchFn(nodeValue)}>
                                <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                    <feather-icon icon="PlusIcon" class="align-feather" />
                                    <span class="font-action ml-2 mt-1">{this.$t('menuCamera.createCameraGroup')}</span>
                                </div>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>;
                }
            }
            if (nodeValue.isOrganizationbranch) {
                if (nodeValue.value && !nodeValue.value.cameraGroupShareResponseDTO) {
                    img = <vx-tooltip class="f-r" text={this.$t('menuCamera.organizationBranch')} position="top">
                        <feather-icon onClick={() => this.nodeSelected(nodeValue)} icon="TrelloIcon"
                            svgClasses="h-6 w-6 cursor-pointer" style="color: #f2574f" />
                    </vx-tooltip>;
                    if (!this.camType && !this.isMap) {
                        addItemBranch = <vs-dropdown vs-trigger-click class="cursor-pointer" svgClasses="w-6 h-6" style="left: 222px;">
                            <feather-icon icon="MoreVerticalIcon" svgClasses="h-6 w-6 cursor-pointer" style={"color: " + titleClass ? "#fff" : "#9faab6"} />
                            <vs-dropdown-menu>
                                <vs-dropdown-item onClick={() => this.showPopupAddGroupCameraFn(nodeValue)}>
                                    <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                        <feather-icon icon="PlusIcon" class="align-feather" />
                                        <span class="font-action ml-2 mt-1">{this.$t('menuCamera.addGroupCamera')}</span>
                                    </div>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>;
                    }
                }
            }

            let addItemGroup = null;
            if (nodeValue.isGroupCamera) {
                if (!nodeValue.isGroupShare) {
                    img = <vx-tooltip class="f-r" text={this.$t('menuCamera.myGroup')} position="top">
                        <feather-icon onClick={() => this.nodeSelected(nodeValue)} icon="PackageIcon" svgClasses="h-6 w-6 cursor-pointer" style="color: #28c76f ;" />
                    </vx-tooltip>
                    if (!this.camType) {
                        addItemGroup = <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <feather-icon icon="MoreVerticalIcon" svgClasses="h-6 w-6 cursor-pointer" style={"color: " + titleClass ? "#fff" : "#9faab6"} />
                            <vs-dropdown-menu>
                                <vs-dropdown-item onClick={() => this.showPopupEditGroupCameraFn(nodeValue)}>
                                    <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                        <feather-icon icon="EditIcon" class="align-feather" />
                                        <span class="font-action ml-2 mt-1">{this.$t('menuCamera.editGroupCamera')}</span>
                                    </div>
                                </vs-dropdown-item>

                                <vs-dropdown-item onClick={() => this.showPopupDeleteGroupCameraFn(nodeValue)}>
                                    <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                        <feather-icon icon="TrashIcon" class="align-feather" />
                                        <span class="font-action ml-2 mt-1">{this.$t('menuCamera.deleteGroupCamera')}</span>
                                    </div>
                                </vs-dropdown-item>

                                <vs-dropdown-item onClick={() => this.shareGroup(nodeValue)}>
                                    <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                        <img class="align-feather-share" src={this.addShareIcon} />
                                        <span class="font-action ml-2">{this.$t('menuCamera.share')}</span>
                                    </div>
                                </vs-dropdown-item>

                                <vs-dropdown-item onClick={() => this.shareGroupHistory(nodeValue)}>
                                    <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                        <img class="align-feather-share" src={this.editShareIcon} />
                                        <span class="font-action ml-2">{this.$t('menuCamera.editShare')}</span>
                                    </div>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>;
                    }
                } else {
                    img = <vx-tooltip class="f-r" text={this.$t('menuCamera.groupShare')} position="top">
                        <feather-icon onClick={() => this.nodeSelected(nodeValue)} icon="PackageIcon" svgClasses="h-6 w-6 cursor-pointer" style="color: #ff9f43;" />
                    </vx-tooltip>
                    addItemGroup = <vs-dropdown vs-trigger-click class="cursor-pointer" svgClasses="w-6 h-6">
                        <feather-icon icon="MoreVerticalIcon" svgClasses="h-6 w-6 cursor-pointer" style={"color: " + titleClass ? "#fff" : "#9faab6"} />
                        <vs-dropdown-menu>
                            <vs-dropdown-item onClick={() => this.deleteGroupShare(nodeValue)}>
                                <div style="min-width: 130pt" class="w-full border-bottom  py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" >
                                    <feather-icon icon="TrashIcon" class="align-feather" />
                                    <span class="font-action ml-2 mt-1">{this.$t('menuCamera.deleteShare')}</span>
                                </div>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>;
                }
            }

            return <span onMouseup={() => this.mouseupMap(nodeValue)} onMousedown={() => this.mousedownMap(nodeValue)}
                style="position: relative; cursor: pointer;"
                class={"span-row-tree " + titleClass + ` ${(!this.disabelOrganization || nodeValue.isCamera) ? 'span-row-tree-hover' : ''}`}>
                {img}
                <span class="node-title pl-2" domPropsInnerHTML={nodeValue.title} onClick={() => {
                    if ((this.disabelOrganization && nodeValue.isCamera) || !this.disabelOrganization) {
                        this.$refs.tree.nodeSelected(nodeValue)
                    }
                }}></span>
                {!this.isMap && addItemGroup}
                {!this.isMap && addItemBranch}
                {!this.isMap && addItemGroupBranch}
                {this.isMap && renderIconMap}
            </span>
        },

        nodeSelected(node) {
            this.saveNode = node;
            this.$emit("onSelectNode", node);
            if (this.isMap) {
                this.$emit("onMouseUpNode", node);
            }
        },

        mousedownMap(node) {
            if (this.isMap) {
                this.$emit("onMouseDownNode", node);
            }
        },

        mouseupMap(node) {
            if (this.isMap) {
                this.$emit("onMouseUpNode", node);
            }
        },

        closePopup: function () {
            this.showPopupNotify = false;
            this.showPopupRename = false;
            this.showPopupDetail = false;
        },

        closePopupAddCamera() {
            this.getHistoryPackage();
            this.showPopupAddCamera = false;
        },

        closePopupAddGroupCamera() {
            this.getHistoryPackage();
            this.showPopupAddGroupCamera = false;
        },

        closePopupAddGroupBranch(val = false) {
            if (val == true) {
                this.getHistoryPackage();
            }
            this.showPopupAddGroupBranch = false;
        },

        closePopupEditGroupCamera() {
            this.getHistoryPackage();
            this.showPopupEditGroupCamera = false;
        },

        deleteGroupShare(node) {
            this.groupCamera = node.value;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `${this.$t('button.titleDelete')}`,
                text: `${this.$t('shareGroupCameraHistory.delete')} "${this.groupCamera.cameraGroupName}" ?`,
                accept: this.deleteGroupRecord,
                acceptText: `${this.$t('button.delete')}`,
                cancelText: `${this.$t('button.cancel')}`,
            })
        },

        deleteGroupRecord() {
            this.$vs.loading();
            this.$crm.post(`/camera-group-share/delete-by-target?cameraGroupId=${this.groupCamera.id}`).then(() => {
                this.$vs.loading.close();
                this.getHistoryPackage();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },

        closePopupDeleteGroupCamera() {
            this.getHistoryPackage();
            this.showPopupDeleteGroupCamera = false;
        },

        closePopupEditCamera() {
            this.showPopupRename = false;
        },

        closePopupDetail() {
            this.showPopupDetail = false;
        },

        closePopupNotification() {
            this.showPopupNotify = false;
        },

        confirmDeleteRecord(item) {
            this.camera.id = item.id;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('warningMenuCamera.titleWarning'),
                text: this.$t('warningMenuCamera.textWarning'),
                accept: this.deleteRecord,
                acceptText: this.$t('warningMenuCamera.acceptText'),
                cancelText: this.$t('warningMenuCamera.cancelText')
            })
        },

        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/camera/delete/${this.camera.id}`).then(() => {
                this.$vs.loading.close();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: this.$t('warningMenuCamera.deleteSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            });
        },

        getChild(node) {
            if (node.children) {
                let children = node.children;
                for (const child of children) {
                    if (child.isCamera) {
                        this.cameras.push(child.value);
                    }
                    if (child.children) this.getChild(child);
                }
            }
        },

        closeShareGroup() {
            this.showPopupShareGroup = false;
        },

        closeShareGroupHistory() {
            this.showPopupShareGroupHistory = false;
        },

        shareGroup(node) {
            this.groupCamera = node.value;
            this.showPopupShareGroup = true;
        },

        shareGroupHistory(node) {
            this.groupCamera = node.value;
            this.showPopupShareGroupHistory = true;
        },

        showPopupDeleteGroupCameraFn(nodeValue) {
            this.objectInfoGroupid = nodeValue.value.id
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('warningMenuCamera.titleWarning'),
                text: this.$t('textCamera.deleteGroup') + ' ' + nodeValue.title,
                accept: this.deleteRecordGroupCamera,
                acceptText: this.$t('warningMenuCamera.acceptText'),
                cancelText: this.$t('warningMenuCamera.cancelText')
            })
        },

        deleteRecordGroupCamera() {
            this.$vs.loading();
            this.$crm.post(`/camera-group/delete/${this.objectInfoGroupid}`).then(() => {
                this.$vs.loading.close();
                this.$eventBus.$emit('handle-reload-tree', true)
                return this.showDeleteGroupCameraSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showDeleteGroupCameraSuccess() {
            this.$vs.notify({
                color: 'success',
                text: this.$t('warningMenuCamera.deleteGroupSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },

        showPopupAddGroupBranchFn() {
            this.showPopupAddGroupBranch = false
            this.showPopupAddGroupBranch = true
        },

        showPopupAddGroupCameraFn(nodeValue) {
            this.$eventBus.$emit('add-group-camera', nodeValue);
            this.showPopupAddGroupCamera = false
            this.showPopupAddGroupCamera = true
        },

        showPopupEditGroupCameraFn(nodeValue) {
            this.$eventBus.$emit('edit-group-camera', nodeValue);
            this.idGroupPopupEdit = nodeValue.value.id
            this.showPopupEditGroupCamera = false
            this.showPopupEditGroupCamera = true
        },

        getHistoryPackage() {
            this.$crm.get(`/camera/find-history-package-by-org`).then(res => {
                this.historyPackage = res.data;
                this.getCamera();
            }).catch(err => {
                console.log('getHistoryPackage error', err)
                this.getCamera();
            });
        },

        clickAddCBranch() {
            this.branchEmpty = false
            setTimeout(() => {
                this.$router.push('/user/add-organization-branch')
            }, 500);
        },

        getCamera() {
            this.$vs.loading();
            this.cameras = [];
            this.objValueCameraMap = [];
            this.sumObjValueCameraMap = [];
            this.camerasBinding = [];
            this.treeData = [{
                isOrganization: true,
                title: null,
                expanded: true,
                children: [],
            }]

            this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
            this.treeData[0].title = `<span class="color"><b>${this.userInfo.username.toUpperCase()}</b></span>`;
            this.treeData[0].idNode = '';
            this.treeData[0].isGod = true;
            this.treeData[0].pathNode = '0';
            // id convention: x1_x2_x3_x4
            // x1: 1: camshare, 0: my camera
            // x2: id branch
            // x3: id parent group, if (-1): camera not in group
            // x4: id camera
            this.$crm.get(`/camera-group/find-camera-by-org?type=${this.camType}`).then((response) => {
                this.$vs.loading.close()
                if (response.data.length == 0) {
                    this.branchEmpty = true
                }
                if (response.data && response.data.length > 0) {
                    let myCamera = {
                        title: `<span class="color"><b>${this.$t('menuCamera.myCamera').toUpperCase()}</b></span>`,
                        expanded: true,
                        isOrganizationbranch: true,
                        value: null,
                        children: [],
                        idNode: '0',
                        layer: 0,
                        pathNode: '0_0',
                    }
                    let cameraShare = {
                        title: `<span class="color"><b>${this.$t('menuCamera.myShareCamera').toUpperCase()}</b></span>`,
                        expanded: true,
                        isOrganizationbranch: true,
                        value: null,
                        children: [],
                        idNode: '1',
                        layer: 0,
                        pathNode: '0_1',
                    }

                    let listOrganizationInGroup = response.data.filter(t => t.cameraGroupShareResponseDTO != null && t.cameraGroupShareResponseDTO.length > 0)

                    listOrganizationInGroup.forEach((data, indexBranch) => {
                        let childOrganizationBranchInGroup = {};
                        let childOrganizationShare = {
                            title: `<span class="color"><b>${data.organizationBranchName.toUpperCase()}</b></span>`,
                            expanded: true,
                            isOrganizationbranch: true,
                            value: data,
                            children: [],
                            idNode: `1_${data.organizationBranchId}`,
                            layer: 1,
                            isCamera: false,
                            pathNode: `0_1_${indexBranch}`,
                        }
                        data.cameraGroupShareResponseDTO.forEach((camShare, indGroup) => {
                            let childGroup = camShare.cameras.map((cam, indCam) => {
                                if (!cam.evgCamera) return;
                                cam.isCameraShare = true;
                                if ((cam.longitude !== null) && (cam.latitude !== null)) {
                                    const valueCameraMap = { position: { lat: cam.latitude, lng: cam.longitude, id: cam.id, cameraName: cam.cameraName } };
                                    this.objValueCameraMap.push(valueCameraMap)
                                }
                                return {
                                    title: cam.cameraName.toUpperCase(),
                                    value: cam,
                                    isCamera: true,
                                    isCameraShare: true,
                                    idNode: `1_${data.organizationBranchId}_${cameraShare.id}_${cam.id}`,
                                    layer: 3,
                                    pathNode: `0_1_${indexBranch}_${indGroup}_${indCam}`,
                                }
                            })
                            childOrganizationBranchInGroup = {
                                title: camShare.cameraGroupName.toUpperCase(),
                                expanded: true,
                                value: camShare,
                                isGroupCamera: true,
                                isGroupShare: true,
                                children: childGroup,
                                idNode: `1_${data.organizationBranchId}_${cameraShare.id}`,
                                layer: 2,
                                isCamera: false,
                                pathNode: `0_1_${indexBranch}_${indGroup}`,
                            }
                            childOrganizationShare.children.push(childOrganizationBranchInGroup);
                        });
                        cameraShare.children.push(childOrganizationShare);
                    })

                    response.data.forEach((data, indexBranch) => {
                        if (data.cameraGroupShareResponseDTO == null || data.cameraGroupShareResponseDTO.length == 0) {
                            let childOrganization = {
                                title: `<span class="color"><b>${data.organizationBranchName.toUpperCase()}</b></span>`,
                                expanded: true,
                                isOrganizationbranch: true,
                                value: data,
                                children: [],
                                idNode: `0_${data.organizationBranchId}`,
                                layer: 1,
                                isCamera: false,
                                pathNode: `0_0_${indexBranch}`,
                            }
                            let childOrganizationBranchInGroup = {};
                            if (data.cameraGroupResponseDTO && data.cameraGroupResponseDTO.length > 0) {
                                data.cameraGroupResponseDTO.forEach((camInGroup, indGroup) => {
                                    let childGroup = camInGroup.cameras.map((cam, indCam) => {
                                        if (cam.aliasId) {
                                            let cameraPackage = this.historyPackage.find(p => cam.aliasId == p.alias)
                                            cam['package'] = cameraPackage;
                                        }
                                        if (!cam.evgCamera) return;
                                        if ((cam.longitude !== null) && (cam.latitude !== null)) {
                                            const valueCameraMap = { position: { lat: cam.latitude, lng: cam.longitude, id: cam.id, cameraName: cam.cameraName, organizationBranchId: cam.organizationBranchId } };
                                            this.objValueCameraMap.push(valueCameraMap)
                                        }
                                        let nodeCam = {
                                            title: cam.cameraName.toUpperCase(),
                                            value: cam,
                                            isCamera: true,
                                            isCameraShare: false,
                                            idNode: `0_${data.organizationBranchId}_${camInGroup.id}_${cam.id}`,
                                            layer: 3,
                                            pathNode: `0_0_${indexBranch}_${indGroup}_${indCam}`,
                                        }
                                        let count = this.count
                                        if (!count) {
                                            this.count = true;
                                            this.fistListCamera.push(nodeCam)
                                            this.$eventBus.$emit('fist-list-camera', nodeCam);
                                        }
                                        return nodeCam;
                                    })
                                    childOrganizationBranchInGroup = {
                                        title: camInGroup.cameraGroupName.toUpperCase(),
                                        expanded: true,
                                        value: camInGroup,
                                        isGroupCamera: true,
                                        isCamera: false,
                                        isGroupShare: false,
                                        children: childGroup,
                                        idNode: `0_${data.organizationBranchId}_${camInGroup.id}`,
                                        layer: 2,
                                        pathNode: `0_0_${indexBranch}_${indGroup}`,
                                    }
                                    childOrganization.children.push(childOrganizationBranchInGroup);
                                });
                            }
                            if (data.camerasNotInGroup && data.camerasNotInGroup.length > 0) {

                                let childOrganizationBranchNotInGroup = data.camerasNotInGroup.map((camNotInGroup, indCam) => {
                                    try {
                                        if (camNotInGroup.aliasId) {
                                            let cameraPackage = this.historyPackage.find(p => camNotInGroup.aliasId == p.alias)
                                            camNotInGroup['package'] = cameraPackage;
                                        }
                                        if ((camNotInGroup.longitude !== null) && (camNotInGroup.latitude !== null)) {
                                            this.objValueCameraMap.push({ position: { lat: camNotInGroup.latitude, lng: camNotInGroup.longitude, id: camNotInGroup.id, cameraName: camNotInGroup.cameraName } })
                                        }
                                        let nodeCamNotInGroup = {
                                            title: camNotInGroup.cameraName.toUpperCase(),
                                            value: camNotInGroup,
                                            isCamera: true,
                                            idNode: `0_${data.organizationBranchId}_-1_${camNotInGroup.id}`,
                                            pathNode: `0_0_${indexBranch}_${indCam + data.cameraGroupResponseDTO.length}`,
                                        }
                                        this.fistListCamera.push(nodeCamNotInGroup)
                                        this.$eventBus.$emit('fist-list-camera', this.fistListCamera);
                                        return nodeCamNotInGroup
                                    } catch (
                                    err
                                    ) {
                                    }

                                })
                                childOrganization.children.push(...childOrganizationBranchNotInGroup);
                            }
                            myCamera.children.push(childOrganization);
                        }
                    });
                    this.sumObjValueCameraMap = this.objValueCameraMap;
                    this.$eventBus.$emit("sumObjValueCameraMap", this.sumObjValueCameraMap);
                    this.treeData[0].children.push(myCamera);
                    this.treeData[0].children.push(cameraShare);
                    this.treeDataOld = this.treeData
                    if (this.camType == 0) {
                        let nodeTmp = this.saveNode ? this.saveNode : this.treeData[0];

                        if (nodeTmp && !nodeTmp.isGod) {
                            let listStr = nodeTmp.idNode.split('_')
                            let idMyOrShare = listStr.length > 0 && listStr[0] || 0;
                            let idBranch = listStr.length > 1 && listStr[1] || null;
                            let idGroup = listStr.length > 2 && listStr[2] || null;
                            let idCamera = listStr.length > 3 && listStr[3] || null;

                            let allNode = this.$refs.tree.getNodes({ title: nodeTmp.title });
                            let passed = false;
                            let currentLayer = nodeTmp.layer;

                            if (currentLayer == 3) {
                                let currentCam = allNode.filter(t => t.title == nodeTmp.title && t.value.id == idCamera);
                                if (currentCam.length >= 1) {
                                    nodeTmp = currentCam[0]
                                    passed = true;
                                } else {
                                    currentLayer--;
                                }
                            }

                            if (currentLayer == 2 && !passed) {
                                let currentGroup = allNode.filter(t => t.title == nodeTmp.title && t.value.id == idGroup);
                                if (currentGroup.length >= 1) {
                                    nodeTmp = currentGroup[0]
                                    passed = true;
                                } else {
                                    currentLayer--;
                                }
                            }

                            if (currentLayer == 1 && !passed) {
                                let currentBranch = allNode.filter(t => t.title == nodeTmp.title && t.value.organizationBranchId == idBranch);
                                if (currentBranch.length >= 1) {
                                    nodeTmp = currentBranch[0]
                                    passed = true;
                                } else {
                                    currentLayer--;
                                }
                            }

                            if (currentLayer == 0 && !passed) {
                                let currentShareOrMy = allNode.filter(t => t.title == nodeTmp.title && t.idNode == idMyOrShare);
                                if (currentShareOrMy.length >= 1) {
                                    nodeTmp = currentShareOrMy[0]
                                    passed = true;
                                }
                            }
                        }

                        this.saveNode = nodeTmp;

                        if (!nodeTmp.isGod) {
                            let vS = nodeTmp.pathNode.split('_');
                            let ind = 1;
                            let tmp = this.treeData[Math.floor(vS[0])];
                            while (ind < vS.length) {
                                tmp = tmp.children[Math.floor(vS[ind])]
                                ind++;
                            }
                            nodeTmp = tmp ? tmp : this.treeData[0]
                        } else {
                            nodeTmp = this.treeData[0]
                        }
                        this.$refs.tree.nodeSelected(nodeTmp)
                    }
                    this.$vs.loading.close();
                }
            }).catch((err) => {
                console.log('ERRROR', err)
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        selectNodeTree(node) {
            let nodeTmp = node ? node : this.treeData[0];
            let listStr = node.pathNode.split('_')
            let idMyOrShare = listStr.length > 0 && listStr[0] || 0;
            let idBranch = listStr.length > 1 && listStr[1] || null;
            let idGroup = listStr.length > 2 && listStr[2] || null;
            let idCamera = listStr.length > 3 && listStr[3] || null;

            let allNode = this.$refs.tree.getNodes({ title: nodeTmp.title });
            let passed = false;
            let currentLayer = node.layer;

            if (currentLayer == 3) {
                let currentCam = allNode.filter(t => t.title == nodeTmp.title && t.value.id == idCamera);
                if (currentCam.length >= 1) {
                    nodeTmp = currentCam[0]
                    passed = true;
                } else {
                    currentLayer--;
                }
            }

            if (currentLayer == 2 && !passed) {
                let currentGroup = allNode.filter(t => t.title == nodeTmp.title && t.value.id == idGroup);
                if (currentGroup.length >= 1) {
                    nodeTmp = currentGroup[0]
                    passed = true;
                } else {
                    currentLayer--;
                }
            }

            if (currentLayer == 1 && !passed) {
                let currentBranch = allNode.filter(t => t.title == nodeTmp.title && t.value.organizationBranchId == idBranch);
                if (currentBranch.length >= 1) {
                    nodeTmp = currentBranch[0]
                    passed = true;
                } else {
                    currentLayer--;
                }
            }

            if (currentLayer == 0 && !passed) {
                let currentShareOrMy = allNode.filter(t => t.title == nodeTmp.title && t.idNode == idMyOrShare);
                if (currentShareOrMy.length >= 1) {
                    nodeTmp = currentShareOrMy[0]
                    passed = true;
                }
            }

            if (!nodeTmp.isGod) {
                let vS = nodeTmp.pathNode.split('_');
                let ind = 1;
                let tmp = this.treeData[Math.floor(vS[0])];
                while (ind < vS.length) {
                    tmp = tmp.children[Math.floor(vS[ind])]
                    ind++;
                }
                nodeTmp = tmp ? tmp : this.treeData[0]
            } else {
                nodeTmp = this.treeData[0]
            }
            this.$refs.tree.nodeSelected(nodeTmp)
        },

        initScreen() {
            this.addShareIcon = addShareIcon;
            this.editShareIcon = editShareIcon;
            this.imgErr = imgErr;
            this.imgLocation = imgLocation;
            this.imgWaiting = imgWaiting;
            this.imgSuccess = imgSuccess;
            this.imgEmpty = imgEmpty;
            this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
            this.treeData[0].title = `<span class="color"><b>${this.userInfo.username}</b></span>`;
            // this.getCamera();
            this.getHistoryPackage();
        },

        resizeCameraTree() {
            let heightScreen = document.documentElement.clientHeight;
            let valueMaxHeight = heightScreen - 250;
            this.maxHeight = valueMaxHeight;
            document.getElementsByClassName("scroll-area--nofications-dropdown")[0].style.maxHeight = `${this.heightTree ? this.heightTree : valueMaxHeight}px`;
        },

        search(keyworld) {
            this.$refs.tree.searchNodes(node => new RegExp(keyworld, 'i').test(node.title))
        },

        clickNodeItem(nodeValue) {
            this.nodeSelected(nodeValue)
        }
    },
    async created() {
        this.initScreen();
    },

    mounted() {
        this.resizeCameraTree();
        this.$eventBus.$on('handle-reload-tree', () => {
            this.getHistoryPackage();
        })

        this.$eventBus.$on('select-camera-item', this.selectNodeTree)
    },

    beforeDestroy() {
        this.$eventBus.$off('handle-reload-tree')
        this.$eventBus.$off('select-camera-item')
        this.$eventBus.$off('fist-list-camera')
        this.$eventBus.$off('check-node-selected')
    },

    watch: {
        searchWord: function (val) {
            this.$refs.tree.searchNodes(node => new RegExp(val, 'i').test(node.title))
        },
        refreshData: function () {
            this.currentPage = 1;
            this.initScreen();
        },
        heightTree: function (val) {
            if (val) {
                this.resizeCameraTree();
            }
        }
    },

}
</script>


<style>

#content-tree-v .halo-tree {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#content-tree-v .halo-tree li {
    padding-right: 0px !important;
}

#content-tree-v .halo-tree .halo-tree li {
    padding-left: 8px;
}

#content-tree-v .halo-tree li:before {
    left: -10px;
}

#content-tree-v .halo-tree li:after {
    left: -10px;
    width: 14px !important;
}

#content-tree-v .halo-tree .tree-node-el {
    display: flex;
    align-items: center;
    background-color: transparent;
}

#content-tree-v #tree-camera .vs-con-dropdown {
    right: 4px;
}

#content-tree-v .halo-tree .node-title {
    text-align: left;
    padding: 3px 0px;
    display: flex;
    flex: 1;
}

#content-tree-v #halo-tree {
    padding-right: 10px;
}

#content-tree-v .halo-tree .span-row-tree {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 4px;
}

#content-tree-v #tree-camera .vs-con-dropdown {
    position: relative !important;
    display: flex !important;
    left: auto !important;
    top: auto !important;
    bottom: auto !important;
}

#content-tree-v #tree-camera .vs-con-dropdown span.feather-icon.select-none {
    bottom: auto !important;
}

#content-tree-v .scroll-area--nofications-dropdown {
    padding-right: 10px;
}

#content-tree-v #tree-camera button span.feather-icon.select-none {
    left: 0px;
}

#content-tree-v #tree-camera .feather-icon {
    padding-top: 4px;
}

#content-tree-v .halo-tree .con-vs-tooltip {
    /* padding-bottom: 7px; */
    padding-left: 6px;
}

#content-tree-v .halo-tree .node-selected {
    border: 1px solid #ddd;
    color: #fff !important;
    background-color: rgba(var(--vs-primary),1) !important;
    transition: all 0.1s;
}

#content-tree-v .halo-tree .span-row-tree-hover:hover {
    border: 1px solid #ddd;
    color: #fff !important;
    background-color: rgba(var(--vs-primary),1) !important;
}

.halo-tree .span-row-tree-hover:hover .node-title,
#content-tree-v .halo-tree .span-row-tree-hover:hover b {
    background-color: rgba(var(--vs-primary),1);
    color: white;
    transition: all 0.1s;
    border-color: transparent;
}

#content-tree-v #tree-camera .align-feather {
    vertical-align: middle;
    width: 1rem;
}

/* .vs-dropdown-menu:hover .vs-dropdown--menu--after{
        background-color: #069862 !important;
    }

    .vs-dropdown-menu:hover li.hover:bg-primary {
        background-color: #069862 !important;
        color: white;
    }

    .vs-dropdown-menu .vs-dropdown--menu--after {
        background-color: #fff !important;
    }

    .vs-dropdown-menu li.hover:bg-primary{
        background-color: #fff !important;
        color: black;
    } */
</style>

<style scoped>
.vs-dropdown--item .vs-dropdown--item-link {
    background: inherit !important;
    color: inherit !important;
    cursor: pointer;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
    position: relative;
    display: block;
    color: rgba(0, 0, 0, .7)
}

.align-feather-share {
    vertical-align: middle;
    width: 16px;
    height: 16px
}
</style>
